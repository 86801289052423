@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url('/fonts/inter/inter-light.woff2') format('woff2'), url('/fonts/inter/inter-light.woff') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: url('/fonts/inter/inter-regular.woff2') format('woff2'), url('/fonts/inter/inter-regular.woff') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url('/fonts/inter/inter-medium.woff2') format('woff2'), url('/fonts/inter/inter-medium.woff') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url('/fonts/inter/inter-semibold.woff2') format('woff2'), url('/fonts/inter/inter-semibold.woff') format('woff');
}

@font-face {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url('/fonts/inter/inter-bold.woff2') format('woff2'), url('/fonts/inter/inter-bold.woff') format('woff');
}
