/* purgecss start ignore */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
/* purgecss end ignore */

@import 'tailwindcss/utilities';

@import './spinner.css';

:root {
    --reach-dialog: 1;
    --reach-tooltip: 1;
    --primary: #edf2f7;
    --primary-dark: #edf2f7;
    --primary-contrast: #22314d;
}
