.spinner {
    width: 62px;
    height: 62px;
    border-radius: 50%;
    border: 2px solid currentColor;
    border-bottom-color: transparent;
    border-right-color: transparent;
    box-sizing: border-box;
    display: inline-block;
    vertical-align: middle;
    -webkit-animation: spinnerMaterialFallback 1s infinite linear;
    animation: spinnerMaterialFallback 1s infinite linear;
}

@supports (
    (-webkit-clip-path: polygon(0 0, 0 0, 0 0, 50% 50%, 0 0, 0 0, 0 0)) or (clip-path: polygon(0 0, 0 0, 0 0, 50% 50%, 0 0, 0 0, 0 0))
) {
    .spinner {
        -webkit-animation: spinnerMaterialSweep 1s linear alternate infinite, spinnerMaterialRota 0.8s linear infinite;
        animation: spinnerMaterialSweep 1s linear alternate infinite, spinnerMaterialRota 0.8s linear infinite;
        border-color: currentColor;
    }
}

@-webkit-keyframes spinnerMaterialRota {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinnerMaterialRota {
    from {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }
    to {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@-webkit-keyframes spinnerMaterialSweep {
    0% {
        -webkit-clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 50% 50%, 0% 0%, 0% 0%, 0% 0%);
        clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 50% 50%, 0% 0%, 0% 0%, 0% 0%);
    }
    50% {
        -webkit-clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 50% 50%, 100% 0%, 100% 0%, 0% 0%);
        clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 50% 50%, 100% 0%, 100% 0%, 0% 0%);
    }
    100% {
        -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 50% 50%, 100% 100%, 100% 0%, 0% 0%);
        clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 50% 50%, 100% 100%, 100% 0%, 0% 0%);
    }
}

@keyframes spinnerMaterialSweep {
    0% {
        -webkit-clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 50% 50%, 0% 0%, 0% 0%, 0% 0%);
        clip-path: polygon(0% 0%, 0% 0%, 0% 0%, 50% 50%, 0% 0%, 0% 0%, 0% 0%);
    }
    50% {
        -webkit-clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 50% 50%, 100% 0%, 100% 0%, 0% 0%);
        clip-path: polygon(0% 0%, 0% 100%, 0% 100%, 50% 50%, 100% 0%, 100% 0%, 0% 0%);
    }
    100% {
        -webkit-clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 50% 50%, 100% 100%, 100% 0%, 0% 0%);
        clip-path: polygon(0% 0%, 0% 100%, 100% 100%, 50% 50%, 100% 100%, 100% 0%, 0% 0%);
    }
}

@-webkit-keyframes spinnerMaterialFallback {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

@keyframes spinnerMaterialFallback {
    0% {
        -webkit-transform: rotate(0deg);
        transform: rotate(0deg);
    }

    100% {
        -webkit-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}
